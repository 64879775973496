import * as constantes from "../constants/constantes";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTTEST = constantes.URL_TESTAPI_LOGIN;
const ENDPOINTTESTUSUARIO = constantes.URL_TESTUSUARIO;


export const ObtenerCursosPorUsuario = async ({jwt,idUsuario}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCursosPorUsuario/${idUsuario}`,{
    //return await fetch(`${ENDPOINTTESTUSUARIO}/ObtenerCursosPorUsuario/${idUsuario}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}



export const ObtenerProgramasPorUsuario = async ({jwt,idPersona}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerProgramasPorUsuario/${idPersona}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerProgramasPorUsuario/${idPersona}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const getPerfil = async ({jwt}) =>{
    return await fetch(`${ENDPOINT}/ZPTAlumno/ObtenerPerfil`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerPerfil`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}


export const ActualizarOnboarding = async ({jwt}) =>{
    return await fetch(`${ENDPOINT}/ZPTAlumno/ActualizarOnboarding`,{
    //return await fetch(`${ENDPOINTTEST}/ActualizarOnboarding`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}
export const EnviarCorreoRecuperacion = async ({jwt,correo}) =>{
    return await fetch(`${ENDPOINT}/ZPTAlumno/EnviarCorreoRecuperacion/${correo}`,{
    //return await fetch(`${ENDPOINTTEST}/EnviarCorreoRecuperacion/${correo}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ValidarGuidRecuperacion = async ({jwt,guid}) =>{
    return await fetch(`${ENDPOINT}/ZPTAlumno/ValidarGuidRecuperacion/${guid}`,{
    //return await fetch(`${ENDPOINTTEST}/ValidarGuidRecuperacion/${guid}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const UpdatePassword= ({jwt,jsonpass}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarContrasena`,{
    return fetch(`${ENDPOINT}/ZPTAlumno/ActualizarContrasena`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonpass
    }).then(res=>{
       //if(!res.ok) throw new Error("Response is Not Ok")
       if(!res.ok) 
       {
           if(res.status == 401)
           {
               window.localStorage.removeItem('jwt')
               window.location.reload();
           }
           else
           {
               throw new Error("No se recibió respuesta del servidor")
           }
       }
       return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ActualizarCursoEstado= ({jsonCurso,jwt}) =>{
    //return fetch(`${ENDPOINTTESTUSUARIO}/ActualizarCursoEstado`,{
    return fetch(`${ENDPOINT}/ZADUsuario/ActualizarCursoEstado`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonCurso
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerCursosPorUsuarioCurso = async ({jwt,idUsuario,idCurso}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCursosPorUsuarioCurso/${idUsuario}/${idCurso}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCursosPorUsuarioCurso/${idUsuario}/${idCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ObtenerPersonaPorId = async ({jwt,idPersona}) =>{
    //return await fetch(`${ENDPOINTTEST}/ObtenerPersonaPorId/${idPersona}`,{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerPersonaPorId/${idPersona}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}
export const ObtenerCursoUsuarioPorId = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCursoUsuarioPorId/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCursoUsuarioPorId/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const SolicitarAmpliacion = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/SolicitarAmpliacion/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/SolicitarAmpliacion/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerPersonaCurso = async ({jwt,IdPersonaCurso}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerPersonaCurso/${IdPersonaCurso}`,{
    //return await fetch(`${ENDPOINTTESTUSUARIO}/ObtenerPersonaCurso/${IdPersonaCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ObtenerCertificadoPersonaCurso = async ({jwt,IdPersonaCurso}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCertificadoPersonaCurso/${IdPersonaCurso}`,{
    //return await fetch(`${ENDPOINTTESTUSUARIO}/ObtenerCertificadoPersonaCurso/${IdPersonaCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ObtenerCertificadoPersonaPrograma = async ({jwt,IDPersonaPrograma}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCertificadoPersonaPrograma/${IDPersonaPrograma}`,{
    //return await fetch(`${ENDPOINTTESTUSUARIO}/ObtenerCertificadoPersonaPrograma/${IDPersonaPrograma}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}
export const ObtenerCursosPorPersonaPrograma = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerCursosPorPersonaPrograma/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerCursosPorPersonaPrograma/${idUsuario}/${idCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ObtenerUnidadesPorPersonaPrograma = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerUnidadesPorPersonaPrograma/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerUnidadesPorPersonaPrograma/${idUsuario}/${idCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ObtenerLeccionesPorPersonaPrograma = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerLeccionesPorPersonaPrograma/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerLeccionesPorPersonaPrograma/${idUsuario}/${idCurso}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ActualizarOnboardingV2= ({usuario,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/ActualizarOnboardingV2`,{
    return fetch(`${ENDPOINT}/ZPTAlumno/ActualizarOnboardingV2`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: usuario
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarAsignarCurso= ({jsonCurso,jwt}) =>{
    //return fetch(`${ENDPOINTTEST}/RegistrarCurso`,{
    return fetch(`${ENDPOINT}/ZADUsuario/RegistrarCurso`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonCurso
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const RegistrarAsignarPrograma= ({jsonPrograma,jwt}) =>{
    // return fetch(`${ENDPOINTTEST}/RegistrarPrograma`,{
    return fetch(`${ENDPOINT}/ZADUsuario/RegistrarPrograma`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonPrograma
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}
export const ObtenerPersonaProgramaPorIdPersonaPrograma = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADUsuario/ObtenerPersonaProgramaPorIdPersonaPrograma/${id}`,{
    //return await fetch(`${ENDPOINTTESTUSUARIO}/ObtenerPersonaProgramaPorIdPersonaPrograma/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}