import { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AlumnoContextProvider } from "./context/alumnoContext";
import ActualizarContrasena from "./pages/Login/ActualizarContrasena";
import RecuperarContrasena from "./pages/Login/RecuperarContrasena";
import { PrivateRoutes, PublicRoutes } from "./routes/routes";
import { ProgressSpinner } from "primereact/progressspinner";
const Login = lazy(() => import("./pages/Login/Login"));
const Home = lazy(() => import("./pages/Home/Home"));
//const Home = lazy(() => import("./pages/private/Home/Home"));

const jwt = window.localStorage.getItem("jwt");
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        <AlumnoContextProvider>
          <Routes>
            <Route
              path="/"
              element={
                Boolean(jwt) ? (
                  <Navigate
                    to={PrivateRoutes.HOME + "/" + PrivateRoutes.DASHBOARD}
                  />
                ) : (
                  <Navigate to={PublicRoutes.LOGIN} />
                )
              }
            />
            <Route path={PublicRoutes.LOGIN} element={<Login />} />
            <Route
              path={PublicRoutes.RECUPERAR}
              element={<RecuperarContrasena />}
            />
            <Route
              path={PublicRoutes.RECUPERAR + "/:guid"}
              element={<ActualizarContrasena />}
            />
            <Route path={PrivateRoutes.HOME + "/*"} element={<Home />} />
          </Routes>
        </AlumnoContextProvider>
      </Suspense>
    </BrowserRouter>
  );
}
export default App;
