import * as constantes from "../constants/constantes";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTTEST = constantes.URL_TESTAPI_LOGIN;
export default async function login ({userName,password}) {
    return await fetch(`${ENDPOINT}/ZPTAlumno/Login`,{
    //return await fetch(`${ENDPOINTTEST}/Login`,{
        method: "POST",
        headers:{
            'Content-Type': 'application/json'
        },
        
        body:JSON.stringify({userName, password})
    }).then(res=>{
        if(!res.ok) throw new Error("Response is Not Ok")
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
       
        return res
    })
    
}
