import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ButtonZegel from "../../../components/Buttons/ButtonZegel";
import DialogZegel from "../../../components/Dialog/DialogZegel";
import InputZegel from "../../../components/Inputs/InputZegel";
import useAlumno from "../../../hooks/useAlumno";
import zegelVirtual from "../../../assets/img/zegelVirtual.png"

import { EnviarCorreoRecuperacion } from "../../../services/UsuarioService";
import "../styles/Login.scss";


function FormRecuperar() {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [contenidoAlert, setContenidoAlert] = useState("");

    const schema = Yup.object().shape({
        correo: Yup.string().required("Correo es un campo obligatorio"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            correo: "",
        },

        validationSchema: schema,
        onSubmit: (values) => {
            let correo = values.correo;
            let jwt = window.localStorage.getItem("jwt");
            EnviarCorreoRecuperacion({ jwt, correo })
                .then((data) => {
                    formik.setSubmitting(false);
                    setContenidoAlert("Se envió correo de recuperación.");
                    setShowAlert(true);
                })
                .catch((errors) => {
                    setContenidoAlert(errors.message);
                    setShowAlert(true);
                    formik.setSubmitting(false);
                });
        },
    });

    const footerAlert = (
        <ButtonZegel
            label="Aceptar"
            color="primary"
            type="button"
            onClick={() => setShowAlert(false)}
        ></ButtonZegel>
    );

    return (
        <>
            <div className="lf-tabLoginRecuperar flex justify-content-center">
                <div className="grid flex align-items-center div_arrow_login" onClick={() => navigate(-1)}>
                    <button className="p-link-button button-img">
                        <i
                            className="pi pi-arrow-left"
                            style={{ fontSize: "1rem" }}
                        ></i>
                    </button>
                </div>
            </div>
            <div className="lf-tabContentLogin flex justify-content-center">
                <div className="grid justify-content-center">
                    <div className="lf-logo flex align-items-center justify-content-center">
                        {/* <img
                            src="https://zegelvirtual.nyc3.cdn.digitaloceanspaces.com/assets/business/zegel-virtual/navbar-logo.svg"
                            height={"44px"}
                            style={{ marginTop: 70, marginBottom: 29 }}
                        ></img> */}
                        <img
                            src={zegelVirtual}
                            height={"100px"}
                            style={{ marginTop: 70, marginBottom: 19 }}
                        ></img>
                         
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="lf-credenciales formgrid grid justify-content-center ">
                            <div className="field col-12 md:col-12  mt-5">
                                <span className="text-title-24">
                                    Recuperación de contraseña
                                </span>
                            </div>
                            <div className="field col-12 md:col-12 ">
                                <span className="text-normal-12">
                                    Ingresa tu cuenta de correo para recibir un enlace con los
                                    pasos para recuperar tu contraseña
                                </span>
                            </div>
                            <div className="field col-12 md:col-12 mb-5 mt-5">
                                <div className="p-float-label">
                                    <InputZegel
                                        id="correo"
                                        name="correo"
                                        value={formik.values.correo}
                                        style={{ width: "100%", height: 50 }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    <label
                                        htmlFor="correo"
                                        style={{ fontSize: 14, color: "#c4c4c4" }}
                                    >
                                        Correo electrónico
                                    </label>
                                </div>
                                <small className="p-error">
                                    {formik.touched.correo && formik.errors.correo}
                                </small>
                            </div>

                            <div className="field col-12 md:col-12 mt-1">
                                <ButtonZegel
                                    label="Recuperar contraseña"
                                    color="primary"
                                    type="submit"
                                    loading={formik.isSubmitting}
                                ></ButtonZegel>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <DialogZegel
                header=""
                visible={showAlert}
                width="30vw"
                onHide={() => setShowAlert(false)}
                footer={footerAlert}
                contenido={contenidoAlert}
            ></DialogZegel>
        </>
    )
}

export default FormRecuperar