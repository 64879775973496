import React from 'react'

function BannerLogin() {
    return (
        <div className="login-fondo">
            <img
                src="https://zegelvirtual.nyc3.cdn.digitaloceanspaces.com/assets/business/zegel-virtual/login-portada.png"
                height={"100%"} style={{ width: "100%", objectFit: "cover", height: "100%" }}
            />
        </div>
    )
}

export default BannerLogin