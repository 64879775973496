import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./styles/Login.scss";
import InputZegel from "../../components/Inputs/InputZegel";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputPastZegel from "../../components/Inputs/InputPassZegel";
import ButtonZegel from "../../components/Buttons/ButtonZegel";
import useAlumno from "../../hooks/useAlumno";
import DialogZegel from "../../components/Dialog/DialogZegel";
import zegelVirtual from "../../../src/assets/img/zegelVirtual.png";

import {
  EnviarCorreoRecuperacion,
  UpdatePassword,
  ValidarGuidRecuperacion,
} from "../../services/UsuarioService";
import { PublicRoutes } from "../../routes/routes";
const ActualizarContrasena = () => {
  const navigate = useNavigate();
  const [errorLogin, setErrorLogin] = useState(null);
  const { login, isLogged, hasLoginError, perfil } = useAlumno();
  const [showAlert, setShowAlert] = useState(false);
  const [contenidoAlert, setContenidoAlert] = useState("");

  const [idUsuario, setIdUsuario] = useState(null);
  let { guid } = useParams();

  useEffect(() => {
    if (guid) {
      let jwt = window.localStorage.getItem("jwt");
      ValidarGuidRecuperacion({ jwt, guid })
        .then((data) => {
          setIdUsuario(data.data);
        })
        .catch((errors) => {
          navigate("/" + PublicRoutes.LOGIN);
        });
    }
  }, [guid]);

  const schema = Yup.object().shape({
    //password: Yup.string().required("Contraseña es un campo obligatorio"),
    // password: Yup.string().required("Contraseña es un campo obligatorio").min(8, "La contraseña debe tener al menos 8 caracteres"),
    password: Yup.string().required("Contraseña es un campo obligatorio")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "La contraseña debe tener al menos 8 caracteres, Una mayúscula, una minúscula"
      )

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordRepetido: "",
    },

    validationSchema: schema,
    onSubmit: (values) => {
      let id = idUsuario; //
      let password = values.password;
      let passwordRepetido = values.passwordRepetido;

      if (passwordRepetido != password) {
        setContenidoAlert("Contraseñas no coinciden.");
        setShowAlert(true);
        formik.setSubmitting(false);
        return;
      }

      let jsonpass = JSON.stringify(
        {
          id,
          guid,
          password,
        },
        null,
        2
      );
      let jwt = window.localStorage.getItem("jwt");
      UpdatePassword({ jwt, jsonpass })
        .then((data) => {
          formik.setSubmitting(false);
          setContenidoAlert("Se actualizó contraseña correctamente.");
          setShowAlert(true);
          setTimeout(() => {
            navigate("/" + PublicRoutes.LOGIN);
          }, 3000);
        })
        .catch((errors) => {
          setContenidoAlert(errors.message);
          setShowAlert(true);
          formik.setSubmitting(false);
        });
    },
  });
  return (
    <>
      {idUsuario && (
        <div className="flex zal-login " style={{ height: "100vh" }}>
          <div className="login-fondo">
            <img
              src="https://zegelvirtual.nyc3.cdn.digitaloceanspaces.com/assets/business/zegel-virtual/login-portada.png"
              height={"100%"}
            />
          </div>
          <div className="login-form">
            <div className="lf-tabLogin flex justify-content-center">
              <div className="grid flex align-items-center">
                <button className="p-link-button button-img">
                  <i
                    className="pi pi-arrow-left"
                    style={{ fontSize: "1rem" }}
                    onClick={() => navigate(-1)}
                  ></i>
                </button>
              </div>
              <div className="grid justify-content-center">
                <div className="lf-logo flex align-items-center justify-content-center">
                  {/* <img
                    src="https://zegelvirtual.nyc3.cdn.digitaloceanspaces.com/assets/business/zegel-virtual/navbar-logo.svg"
                    height={"44px"}
                    style={{ marginTop: 130, marginBottom: 29 }}
                  ></img> */}
                  <img
                    src={zegelVirtual}
                    height={"100px"}
                    style={{ marginTop: 130, marginBottom: 29 }}
                  ></img>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="lf-credenciales formgrid grid justify-content-center ">
                    <div className="field col-12 md:col-12  mt-5">
                      <span className="text-title-24">
                        Crear una nueva contraseña
                      </span>
                    </div>
                    <div className="field col-12 md:col-12 ">
                      <span className="text-normal-12">
                        Tu nueva contraseña debe poseer una extensión de al
                        menos 8 caracteres y contener una mayúscula y un número.
                      </span>
                    </div>
                    <div className="field col-12 md:col-12 mt-3">
                      <div className="p-float-label">
                        <InputPastZegel
                          id="password"
                          name="password"
                          value={formik.values.password}
                          style={{ width: "100%", height: 50 }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          htmlFor="pass"
                          style={{ fontSize: 14, color: "#c4c4c4" }}
                        >
                          Nueva contraseña
                        </label>
                      </div>
                      <small className="p-error">
                        {formik.touched.password && formik.errors.password}
                      </small>
                    </div>
                    <div className="field col-12 md:col-12 mt-3">
                      <div className="p-float-label">
                        <InputPastZegel
                          id="passwordRepetido"
                          name="passwordRepetido"
                          value={formik.values.passwordRepetido}
                          style={{ width: "100%", height: 50 }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <label
                          htmlFor="pass"
                          style={{ fontSize: 14, color: "#c4c4c4" }}
                        >
                          Repetir contraseña
                        </label>
                      </div>
                      <small className="p-error">
                        {formik.touched.passwordRepetido &&
                          formik.errors.passwordRepetido}
                      </small>
                    </div>

                    <div className="field col-12 md:col-12 mt-1">
                      <ButtonZegel
                        label="Actualizar contraseña"
                        color="primary"
                        type="submit"
                        loading={formik.isSubmitting}
                      ></ButtonZegel>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <DialogZegel
            header=""
            visible={showAlert}
            width="30vw"
            onHide={() => setShowAlert(false)}
            footer={<></>}
            contenido={contenidoAlert}
          ></DialogZegel>
        </div>
      )}
    </>
  );
};

export default ActualizarContrasena;
