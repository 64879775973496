import * as constantes from "../constants/constantes.js";
const ENDPOINT = constantes.URLAPI;
const ENDPOINTTEST = constantes.URL_TESTPREGUNTA;

export const ListarPreguntasPorLeccion = async({jwt,idLeccion})=> {
    return await fetch(`${ENDPOINT}/ZADPregunta/ListarPreguntasPorLeccion/${idLeccion}`,{
    //return await fetch(`${ENDPOINTTEST}/ListarPreguntasPorLeccion/${idLeccion}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const ListarPreguntasPorUnidad = async({jwt,idUnidad})=> {
    var IDLeccion =  idUnidad
    return await fetch(`${ENDPOINT}/ZADPregunta/ObtenerListaPreguntasPorUnidad/${IDLeccion}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerListaPreguntasPorUnidad/${idUnidad}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const BuscarPreguntaID = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADPregunta/BuscarPreguntaID/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/BuscarPreguntaID/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}
export const ObtenerAlternativasPorUnidad = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADPregunta/ObtenerAlternativasPorUnidad/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerAlternativasPorUnidad/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}

export const EvaluarExamen= ({jsonExamen,jwt}) =>{
    // return fetch(`${ENDPOINTTEST}/EvaluarExamen`,{
    return fetch(`${ENDPOINT}/ZADPregunta/EvaluarExamen`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonExamen
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        //console.log("EvaluarExamenRes:",res);
        if(res?.Result == false) {
            throw new Error(res.errors[0])
        }
        const {data} = res
        //console.log(data);
        return data
    })
}

export const RegistrarEvaluacion= ({jsonEvaluacion,jwt}) =>{
    // return fetch(`${ENDPOINTTEST}/RegistrarEvaluacion`,{
    return fetch(`${ENDPOINT}/ZADPregunta/RegistrarEvaluacion`,{
        method: "POST",
        headers:{
            "Authorization":"Bearer "+jwt,
            'Content-Type': 'application/json',
            "accept": "application/json"
        },
        
        body: jsonEvaluacion
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
}

export const ObtenerRespuestaAlumnosPorEvaluacion = async ({jwt,id}) =>{
    return await fetch(`${ENDPOINT}/ZADPregunta/ObtenerRespuestaAlumnosPorEvaluacion/${id}`,{
    //return await fetch(`${ENDPOINTTEST}/ObtenerRespuestaAlumnosPorEvaluacion/${id}`,{
        method: "GET",
        headers:{
            "Authorization":"Bearer "+jwt,
            //'Content-Type': 'application/json'
            "accept": "text/plain"
        },
        
    }).then(res=>{
        //if(!res.ok) throw new Error("Response is Not Ok")
        if(!res.ok) 
        {
            if(res.status == 401)
            {
                window.localStorage.removeItem('jwt')
                window.location.reload();
            }
            else
            {
                throw new Error("No se recibió respuesta del servidor")
            }
        }
        return res.json()
    }).then(res=>{
        if(res.errors) throw new Error(res.errors[0])
        const {data} = res
        return data
    })
    
}