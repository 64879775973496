
//MARK: URL QA
//export const URLAPI = "https://qazegelapi.azure-api.net"
//MARK: URL PRD
export const URLAPI = "https://prdzegelapi.azure-api.net"

export const URL_TESTAPI_LOGIN = "http://localhost:7208/api"
export const URL_AYUDA = "https://bit.ly/CanaldeAtenci%C3%B3nSAE"


// export const URL_WEB_BASE="http://localhost:3000/"
export const URL_WEB_BASE="https://campus.zegelvirtual.com/"

export const URL_TESTCURSO = "http://localhost:7116/api"

export const URL_TESTMATERIAL = "http://localhost:7003/api"

export const URL_TESTPREGUNTA ="http://localhost:7089/api"

export const URLAPIGENERATECER = "https://generatecertificado.azurewebsites.net"

export const URL_TESTUSUARIO ="http://localhost:7110/api"