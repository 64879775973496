export const PublicRoutes = {
    LOGIN: "Login",
    RECUPERAR:"Login/RecuperarContrasena",
    ACTUALIZAR_PASS:"Login/ActualizarContrasena"
  };
  
  export const PrivateRoutes = {
    HOME: "Inicio",
    DASHBOARD: "Dashboard"
  };
  