import BannerLogin from "./components/BannerLogin";
import FormRecuperar from "./components/FormRecuperar";
import "./styles/Login.scss";
const RecuperarContrasena = () => {

  return (
    <div className="flex zal-login " style={{ height: "100vh" }}>
      <BannerLogin />
      <div className="login-form">
        <FormRecuperar />
      </div>

    </div>
  );
};

export default RecuperarContrasena;
