import { Button } from "primereact/button";
import "./styles/ButtonZegel.scss";
const ButtonZegel = (props) => {
  return (
    <Button
      {...props}
      className={`button-zegel ${props.color == "primary" ? "primary" : ""} ${props.color == "primary-2" ? "primary-2" : ""
        } ${props.color == "secondary" ? "secondary" : ""}  ${props.color == "terceary" ? "terceary" : ""
        } ${props.color == "Quaternary" ? "Quaternary" : ""} ${props.color == "Black" ? "Black" : ""
        }
        ${props?.clases? " "+props?.clases: ""}
        `}
      style={{
        width: props.width ? props.width : "100%",
        height: props.height,
      }}
    ></Button>
  );
};

export default ButtonZegel;
