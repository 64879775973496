import { Dialog } from "primereact/dialog";
import "./styles/DialogZegel.scss";
const DialogZegel = (props) => {
  return (
    <Dialog
      {...props}
      className={props.clase ? "dialog-zegel " + props.clase : "dialog-zegel"}
      header={props.header}
      visible={props.visible}
      style={{ width: props.width, padding: props.padding }}
      onHide={props.onHide}
      footer={props.footer}
      resizable={false}
      draggable={false}
    >
      <p className="m-0" style={{ maxHeight: '100%' }}>{props.contenido}</p>
    </Dialog>
  );
};

export default DialogZegel;
