import React, { useEffect, useState } from "react";
import { ObtenerConfiguraciones } from "../services/GeneralServices";
import { EvaluarExamen } from "../services/PreguntaService";
import { getPerfil } from "../services/UsuarioService";
const Context = React.createContext({});

export function AlumnoContextProvider({ children }) {
  const [perfil, setPerfil] = useState(null);
  const [jwt, setJwt] = useState(() => window.localStorage.getItem("jwt"));
  const [configuraciones, setConfiguraciones] = useState(null);
  const [activosChecked, setActivosChecked] = useState("curso-activo");
  const [finalizadoChecked, setFinalizadoChecked] = useState("");
  const [explorarChecked, setExplorarChecked] = useState("");
  const [indexActiveCursos, setIndexActiveCursos] = useState(0);
  const [indexTabPrincipal, setIndexTabPrincipal] = useState(0);
  const [indexTabSecundario, setIndexTabSecundario] = useState(0);
  useEffect(() => {
    if (!jwt) return setPerfil({});
    getPerfil({ jwt })
      .then(setPerfil)
      .catch((err) => {
        window.localStorage.removeItem("jwt");
      });
  }, [jwt]);

  const handleEnviar = (idUnidad, idEvaluacion, preguntasRespondidas = []) => {
    //setLoading(true);
    let jwt = window.localStorage.getItem("jwt");
    let IdUnidad = idUnidad;
    let Respuestas = preguntasRespondidas.split(",");
    let IdEvaluacion = idEvaluacion;
    let jsonExamen = JSON.stringify(
      {
        IdUnidad,
        IdEvaluacion,
        Respuestas,
      },
      null,
      2
    );
    EvaluarExamen({ jsonExamen, jwt })
      .then((data) => {
        sessionStorage.removeItem("respuestas_alumno");
      })
      .catch((err) => {
        sessionStorage.removeItem("respuestas_alumno");
      });
  };

  const verificarYGuardarRespuestas = () => {
    let respuestas = [];
    if (
      sessionStorage.getItem("respuestas_alumno") &&
      !window.location.href.includes(
        `${window.location.host}/Inicio/Evaluacion/Examen`
      ) &&
      !window.location.href.includes(`${window.location.host}/Login`)
    ) {
      respuestas = sessionStorage.getItem("respuestas_alumno");
      let array = respuestas.split(";");
      let respuestaAlumno = array[2] ? array[2] : [];
      handleEnviar(array[0], array[1], respuestaAlumno);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(verificarYGuardarRespuestas, 4000);

    // Limpieza del intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!jwt) return setConfiguraciones({});
    ObtenerConfiguraciones({ jwt })
      .then(setConfiguraciones)
      .catch((err) => {
        window.localStorage.removeItem("jwt");
      });
  }, [jwt]);
  return (
    <Context.Provider
      value={{
        perfil,
        jwt,
        setPerfil,
        setJwt,
        setConfiguraciones,
        configuraciones,
        activosChecked,
        setActivosChecked,
        finalizadoChecked,
        setFinalizadoChecked,
        explorarChecked,
        setExplorarChecked,
        indexActiveCursos,
        setIndexActiveCursos,
        indexTabPrincipal,
        setIndexTabPrincipal,
        indexTabSecundario,
        setIndexTabSecundario,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Context;
