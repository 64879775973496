import { InputText } from 'primereact/inputtext';
import "./styles/InputZegel.scss";
const InputZegel = (props) => {
    return (
        <InputText
            className='input-zegel'
            {...props}

        ></InputText>
    );
}

export default InputZegel;