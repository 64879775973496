import { useCallback, useContext, useState } from "react";
import Context from "../context/alumnoContext";
import loginService from "../services/LoginService";
export default function useAlumno() {
  const {
    jwt,
    setJwt,
    perfil,
    setConfiguraciones,
    configuraciones,
    activosChecked,
    setActivosChecked,
    finalizadoChecked,
    setFinalizadoChecked,
    explorarChecked,
    setExplorarChecked,
    indexActiveCursos,
    setIndexActiveCursos,
    indexTabPrincipal,
    setIndexTabPrincipal,
    indexTabSecundario,
    setIndexTabSecundario,
  } = useContext(Context);
  const [loadingGlobal, setLoadingGlobal] = useState(false);
  const [state, setState] = useState({
    loading: false,
    error: false,
    message: null,
  });
  const login = useCallback(
    async ({ userName, password }, setField) => {
      //setJwt("test")
      await loginService({ userName, password })
        .then((res) => {
          const { token, change } = res;
          window.localStorage.setItem("jwt", token);
          window.localStorage.setItem("reset", change);
          setField(false);
          setState({ loading: false, error: false, message: "success" });
          setJwt(token);
        })
        .catch((err) => {
          window.localStorage.removeItem("jwt");
          window.localStorage.removeItem("reset");
          setField(true);
          setState({ loading: false, error: true, message: err.message });
          logout();
        });
    },
    [setJwt]
  );
  const logout = useCallback(() => {
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("reset");
    setJwt(null);
  }, [setJwt]);
  return {
    isLogged: Boolean(jwt),
    login,
    logout,
    hasLoginError: state.error,
    hasMesageError: state.message,
    perfil,
    setConfiguraciones,
    configuraciones,
    activosChecked,
    setActivosChecked,
    finalizadoChecked,
    setFinalizadoChecked,
    explorarChecked,
    setExplorarChecked,
    indexActiveCursos,
    setIndexActiveCursos,
    indexTabPrincipal,
    setIndexTabPrincipal,
    indexTabSecundario,
    setIndexTabSecundario,
    loadingGlobal,
    setLoadingGlobal,
  };
}
